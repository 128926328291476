import http from './request'
import store from '@/store'

export default function ({ loadText, url, method, data, params }) {
  if (!loadText) {
    loadText = '拼命加载中...'
  }
  store.commit('changeLoadText', loadText)
  const parameters = {
    url,
    method
  }
  if (method.toUpperCase() === 'POST') {
    parameters.data = data
  } else {
    parameters.params = params
  }
  return http(parameters)
}
