export const yAxiSignal = {
  show: true,
  name: '信号',
  splitLine: {
    show: false
  },
  type: 'value',
  position: 'right',
  min: undefined,
  max: undefined,
  offset: undefined,
  scale: true,
  nameTextStyle: {
    align: 'left'
  },
  axisLine: {
    lineStyle: {
      color: '#5c427c'
    }
  },
  axisLabel: {
    formatter: '{value}'
  }
}

//速度y轴线
export const yAxiSpeed = {
  show: false,
  name: 'km/h',
  splitLine: {
    show: false
  },
  type: 'value',
  min: undefined,
  max: undefined,
  offset: 60,
  position: 'right',
  nameTextStyle: {
    align: 'left'
  },
  axisLine: {
    lineStyle: {
      color: '#5793f3'
    }
  },
  axisLabel: {
    formatter: '{value}'
  }
}

export const yAxiWeight = {
  show: true,
  name: '重量',
  type: 'value',
  position: 'left',
  axisLine: {
    show: true,
    lineStyle: {
      color: 'gray',
      fontSize: 12
    }
  },
  axisLabel: {
    formatter: '{value}'
  }
}
