import { ref } from 'vue'
// 是否使用返回动画， ios 本身就有动画效果
export function useAnimation() {
  const userAgent = navigator.userAgent
  const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  const animationName = ref('slide')
  if (isIOS) animationName.value = 'none'

  return {
    animationName
  }
}
